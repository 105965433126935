<div class="container mt-n10" style="margin-top: 10%">
  <div class="row justify-content-center">
    <div class="justify-content-center">
      <div>
        <div>
          <div class="row align-items-center">
            <div>
              <div>
                <h3
                  style="font-family: 'Volvo Novum', sans-serif !important; color: #0d3896 !important; font-size: 80%"
                >
                  OOOPS :( <br /><br />
                  {{ 'Parece que a página não existe ou você não tem permissão para acessar!' | translate }}
                </h3>
                <p style="font-family: 'Volvo Novum', sans-serif !important; color: #0d3896 !important; font-size: 80%">
                  {{ 'Para solicitar acesso,' | translate
                  }}<a href="http://faros.volvo.com/UserAdm/default.aspx">{{ 'clique aqui' | translate }}</a>
                </p>
              </div>
            </div>
            <div>
              <img class="img-fluid" style="max-width: 19rem" src="/assets/img/at-work.JPG" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
