// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  EnvironmentPlaceName: 'qa',
  production: false,
  clientId: '0283e33b-c101-41ad-93af-63d182db337c',
  authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  redirectUri: 'https://laservicecontract-qa.volvo.com',
  postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  OcpToken: '',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  appInsights: {
    instrumentationKey: 'd1df896a-f5fe-4311-8640-c74c326bf110',
  },
  //API Paths
  UrlApiContract: 'https://laservicecontract-api-qa.volvo.com/v1/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
