import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SetDefaultLanguageService } from './setDefaultLanguage';

@Injectable({
  providedIn: 'root',
})
export class GetTranslationService {
  constructor(
    private translateSvc: TranslateService,
    private setLang: SetDefaultLanguageService,
  ) {
    this.translateSvc.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateSvc.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
  }

  getTranslation(toTranslate: string): string {
    let translation: string = '';

    this.translateSvc.get(toTranslate).subscribe(
      (response: string) => {
        translation = response;
      },
      (error) => {
        return error;
      },
    );

    return translation;
  }

  translateBR(toTranslate: string[]): string[] {
    let translation: any = [];
    this.translateSvc.use('br');

    for (var item of toTranslate) {
      this.translateSvc.get(item.toString()).subscribe(
        (response: string) => {
          translation.push(response);
        },
        (error) => {
          return error;
        },
      );
    }
    this.translateSvc.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    return translation;
  }
}
