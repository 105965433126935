import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountryParametersService extends BaseService {
  private countryParameter = new BehaviorSubject<any>(null);
  public data$ = this.countryParameter.asObservable();

  constructor(
    private http: HttpClient,
    protected override loggingService: LoggingService,
  ) {
    super(loggingService);
  }

  fetchCountryParameter(countryCode: string): Observable<any> {
    return this.http
      .get(`${environment.UrlApiContract}CountryParameter/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        tap((data) => this.countryParameter.next(data)),
        catchError((err) => this.serviceError(err)),
      );
  }

  getCountryParameters(): Observable<any> {
    return this.data$;
  }
}
