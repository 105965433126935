import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';
import { LocalStorageUtils } from '../shared/utils/localstorage';
import { LoggingService } from './logging.service';
export abstract class BaseService {
  protected UrlCustomerApi: string = '';
  public LocalStorage = new LocalStorageUtils();
  roleId: string = '';

  constructor(protected loggingService: LoggingService) {}

  protected GetHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
      }),
    };
  }
  protected imageHeader() {
    let ocpToken = environment.OcpToken;
    return {
      headers: new HttpHeaders({
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        'Ocp-Apim-Subscription-Key': ocpToken,
        Authorization: `Bearer ${this.LocalStorage.getUserToken()}`,
        roleId: AppComponent.globalRole[0],
      }),
    };
  }

  protected GetAuthTokenHeader() {
    let ocpToken = environment.OcpToken;
    return {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': ocpToken,
        Authorization: `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        roleId: AppComponent.globalRole[0],
      }),
    };
  }

  protected GetAuthTokenHeaderOnlyToken() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.LocalStorage.getUserToken()}`,
      }),
    };
  }

  protected GetAuthTokenHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.OcpToken,
        Authorization: `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        roleId: AppComponent.globalRole[0],
      }),
    };
  }

  protected GetAuthTokenForReport() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Ocp-Apim-Subscription-Key': environment.OcpToken,
        Authorization: `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        roleId: AppComponent.globalRole[0],
      }),
    };
  }

  protected extract(response: any) {
    return response || {};
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];

    this.loggingService.logException(response.error);
    if (response.statusText === 'Unknown Error') {
      customError.push('Ocorreu um erro desconhecido');
      response.error.errors = customError;
    }

    if (response.status === 401) {
      let countryCode = localStorage.getItem('countryCode');
      let language = localStorage.getItem('language');
      localStorage.clear();
      localStorage.setItem('countryCode', countryCode!);
      localStorage.setItem('language', language!);
      //document.location.href="/";
      window.location.reload();
    }
    return throwError(() => response.error);
  }
}
