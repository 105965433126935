import { EnumCountryCode } from '../models/enumCountryCode';

export class LocalStorageUtils {
  constructor() {}
  public getUserToken(): string | null {
    let key = <any>localStorage.getItem('TokenKey');

    if (key == null) return '';

    let token = <any>localStorage.getItem(key.toString());
    return JSON.parse(token).secret;
  }

  getAccessToken(): string {
    let key = <any>localStorage.getItem('TokenAccess');

    if (key == null) return '';

    let token = <any>localStorage.getItem(key.toString());
    return JSON.parse(token).secret;
  }

  public getCurrentLanguage(): string | null {
    let language = <any>localStorage.getItem('language');
    if (language != null && language != '' && language != undefined) {
      switch (language) {
        case 'br': {
          return 'pt-BR';
        }
        case 'us': {
          return 'en-US';
        }
        case 'es-AR': {
          return 'es-AR';
        }
        case 'es-PE': {
          return 'es-PE';
        }
        default: {
          return 'pt-BR';
        }
      }
    } else {
      return 'pt-BR';
    }
  }

  public getCurrentCountryCode(): string {
    let countryCode = <any>localStorage.getItem('countryCode');
    if (
      countryCode != null &&
      countryCode != '' &&
      countryCode != undefined &&
      EnumCountryCode[countryCode as keyof typeof EnumCountryCode] != undefined
    ) {
      return countryCode;
    } else {
      return 'NOT_DEFINED';
    }
  }

  public GetEnumCountryCode() {
    return EnumCountryCode[this.getCurrentCountryCode() as keyof typeof EnumCountryCode];
  }
}
