import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { CustomerService } from 'src/app/modules/customer/services/customer.service';
import { InconsistencyService } from 'src/app/modules/inconsistencies/services/inconsistency.service';
import { EnumCountryCode } from 'src/app/shared/models/enumCountryCode';
import { CountryParameter } from 'src/app/shared/models/Parameters';
import { CountryParametersService } from 'src/app/shared/services/country-parameters.service';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { MenuService } from '../../service/menu.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
  displayName?: string;
};
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  public localStorage = new LocalStorageUtils();

  _opened: boolean = false;
  public isCollapsed: boolean;
  loginDisplay = false;
  closeResult = '';
  isDeveloper = false;
  isSelected = false;
  countryCode!: string;
  countryId = '';
  role!: string;
  country!: string;
  countryDropdown!: string;
  initials!: string | undefined;
  countriesList: any;

  profile!: ProfileType;
  profileId!: string;
  inconsistencyCount: number = 0;
  countryParameters!: CountryParameter;

  constructor(
    public appComponent: AppComponent,
    private menuService: MenuService,
    private sanitizer: DomSanitizer,
    private authService: MsalService,
    private http: HttpClient,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private inconsistencyService: InconsistencyService,
    public getTranslationSvc: GetTranslationService,
    private customerService: CustomerService,
    private countryParametersService: CountryParametersService,
  ) {
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
    this.isCollapsed = true;

    this.countryId = EnumCountryCode[this.localStorage.getCurrentCountryCode() as keyof typeof EnumCountryCode];
  }

  ngOnInit(): void {
    this.setLoginDisplay();
    this.getProfile();
    this.getProfileRole();
    this.setCountry();

    this.countryParametersService.fetchCountryParameter(this.countryId).subscribe((response) => {
      this.countryParameters = response.data;
    });

    this.menuService.getCountriesDropdown().subscribe((response) => {
      this.countriesList = response.data.countries;
    });

    this.inconsistencyService.getAllInconsistencies(1, 0, {}, this.countryId).subscribe((response) => {
      this.inconsistencyCount = response.data.total;
    });
  }

  setCountryOrganization(event: any) {
    let code: string = event.detail.value;
    code = code.substring(0, 2);
    localStorage.setItem('countryCode', code);
    window.location.reload();
  }

  setCountry() {
    this.countryCode = this.localStorage.getCurrentCountryCode();
    if (this.countryCode == 'AR') {
      this.country = 'Argentina';
      this.countryDropdown = 'ARG';
    } else if (this.countryCode == 'BR') {
      this.country = 'Brasil';
      this.countryDropdown = 'BRA';
    } else if (this.countryCode == 'PE') {
      this.country = 'Peru';
      this.countryDropdown = 'PE';
    }
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.initials =
        this.profile.displayName!.charAt(this.profile.displayName!.indexOf(' ') + 1) + this.profile.displayName![0];
    });
  }

  getProfileRole() {
    let key = <any>localStorage.getItem('TokenClaims2');
    key = JSON.parse(key);
    //let token = <any>localStorage.getItem(key.toString());
    this.role = key.roles[0];
    if (this.countryId == 'NOT DEFINED' && this.role === 'admin') {
      this.countryId = 'BRA';
      <any>localStorage.setItem('countryCode', 'BR');
    }
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  logout() {
    window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout';
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
  }
}
