import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

const s = document.createElement('meta');
s.setAttribute('http-equiv', 'Content-Security-Policy');
s.setAttribute(
  'content',
  "default-src 'self' 'unsafe-inline' https://login.microsoftonline.com; connect-src 'self' " +
    environment.UrlApiContract +
    " https://login.microsoftonline.com/ https://graph.microsoft.com/ https://dc.services.visualstudio.com/; style-src-elem 'self' 'unsafe-inline' https://fonts.googleapis.com/ https://cdnjs.cloudflare.com/ https://fonts.gstatic.com/; img-src 'self' https:; font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com/;",
);
const head = document.getElementsByTagName('head')[0];
head.appendChild(s);
