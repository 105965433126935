<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  [fullScreen]="true"
  template="<img class='custom-spinner-in animate__animated animate__backInRight animate__infinite	' src='assets/img/truck-loading-.png' />
            <img class='custom-spinner-out animate__animated animate__bounceOutLeft animate__infinite' src='assets/img/truck-loading-.png' />"
>
  <p style="color: white; font-family: Volvo Broad Pro Digital; letter-spacing: 1px; font-size: 18px">
    {{ 'Carregando...' | translate }}
  </p>
</ngx-spinner>
<app-menu *ngIf="staticGlobalRole !== ''"></app-menu>
<app-footer></app-footer>
