import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';
import { ProfileType } from '../Component/profile/profileTypeModel';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends BaseService {
  profile!: any;
  profilePthoto!: any;
  profileImg!: any;
  profilePictureUrl!: string;
  profileId$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
    protected override loggingService: LoggingService,
    private sanitizer: DomSanitizer,
  ) {
    super(loggingService);
  }

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }

  getProfile() {
    this.http.get<ProfileType>(environment.GRAPH_ENDPOINT).subscribe((profile) => {
      this.profileId$.next(profile.id);
    });
  }

  getCountriesDropdown(): Observable<any> {
    return this.http.get<any>(`${environment.UrlApiContract}Country`, super.GetAuthTokenHeader()).pipe(
      map(super.extract),
      catchError((err) => this.serviceError(err)),
    );
  }
}
