import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { PagedResponse } from 'src/app/services/grid-data-source.service';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';
import { Inconsistency } from '../models/inconsistency';

@Injectable({
  providedIn: 'root',
})
export class InconsistencyService extends BaseService {
  constructor(
    private http: HttpClient,
    protected override loggingService: LoggingService,
  ) {
    super(loggingService);
  }

  getAllInconsistencies(
    page: number,
    pageSize: number,
    filterObject: any,
    countryCode: string,
  ): Observable<PagedResponse<Inconsistency>> {
    return this.http
      .get<
        PagedResponse<Inconsistency>
      >(`${environment.UrlApiContract}Notification/GetAllCritical/${countryCode}/${page}/${pageSize}`, { params: filterObject, headers: super.GetAuthTokenHeader().headers })
      .pipe(
        map(super.extract),
        catchError((err) => this.serviceError(err)),
      );
  }

  getInconsistenciesFilters(countryCode: string): Observable<any> {
    return this.http
      .get<
        PagedResponse<Inconsistency>
      >(`${environment.UrlApiContract}Notification/GetAllOptionsFiltersNotifications/${countryCode}`, super.GetAuthTokenHeader())
      .pipe(
        map(super.extract),
        catchError((err) => this.serviceError(err)),
      );
  }
}
